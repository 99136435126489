.image-gallery-slide img {
  width: 100% !important;
  height: 100% !important;
}

.image-gallery-swipe {
  width: 100%;
  height: 100%;
}

.image-gallery-slides {
  width: 100%;
  height: 100%;
}

.image-gallery-slide .image-gallery-image {
  width: 100% !important;
  height: 100%;
}

.image-gallery-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  height: 70% !important;
  width: 100% !important;
}

.image-gallery-slide-wrapper {
  height: 100%;
  width: 100%;
}
.image-gallery-thumbnail-wrapper {
  height: 100%;
  width: 100%;
}

.react-multiple-carousel__arrow{
  z-index: 2;
}
