.carousel .slide img {
  height: 100% !important;
  object-fit: cover;
  width: '100%';
}

.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;
}
